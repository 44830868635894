<template>
	<div class="order">

		<div class="order__content">
			<div class="order__video">
				<video v-if="isMD" autoplay muted loop playsinline class="anim-fade-in">
					<source src="@/assets/home/intro/video-background.mp4" type="video/mp4">
				</video>
				<video v-else autoplay muted loop playsinline class="anim-fade-in">
					<source src="@/assets/home/intro/video-background-mobile.mp4" type="video/mp4">
				</video>
			</div>

			<div class="order__text">
				<v-container>
					<v-row>
						<v-col cols="12" class="text-center">

							<div>
								<div class="mt-15">
									<svgicon name="logo" original class="logo"/>
									<h1 class="mt-8 d-flex align-center justify-center"><span class="dot white"></span>{{order.ID}}<span
											class="dot white"></span></h1>
									<p>{{page.texts.ORDER_NUMBER.NAME}}</p>
								</div>

								<h1 class="mt-8">{{page.texts.ORDER_THANK2.NAME}}</h1>
								<template v-if="withPayment">
									<p class="mt-4">{{page.texts.ORDER_WE_PLEASE.NAME}}<br/>
										{{page.texts.ORDER_PLEASE_W.NAME}}<!--Please wait-->...</p>

									<v-progress-circular class="mt-8"
														 indeterminate
														 color="white"/>
								</template>
								<template v-else>
									<p class="mt-4">We are already preparing your lovely candies!</p>
								</template>
							</div>

						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>

	</div>
</template>

<script>
	import vp from "@/mixins/vp";

	export default {
		name: "order",
		mixins: [vp],
		data() {
			return {}
		},
		computed: {
			withPayment() {
				return !!this.paymentUrl;
			},
			paymentUrl() {
				return this.$store.state.page?.OrderController?.stripe?.url;
			},
			order() {
				return this.$store.state.page?.OrderController?.order;
			},
            page() {
                return this.$store.state.page.OrderController;
            },
		},
		mounted() {
			if (this.paymentUrl) {
				setTimeout(() => {
					self.location.href = this.paymentUrl;
				}, 3000);
			}
		}
	}
</script>

<style lang="scss">
	.order {

		&__content {
			position: relative;
			height: 640px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $black;
		}

		&__video {
			//width: 320px;
			//margin: 0 auto;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0.4;

			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				//mix-blend-mode: lighten;
				//animation: fade-in 2s forwards;
			}
		}

		&__text {
			position: relative;
			z-index: 1;

			p, span {
				color: $white;
			}

			.logo {
				width: 128px;
				height: 128px;
			}
		}
	}
</style>
